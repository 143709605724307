import React, { Fragment } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Blog from "../../../components/blog/layout-two";
import Pagination from "../../../components/blog/pagination";
import { BlogWrapper, BlogBox } from "./blog-area.style";

const BlogArea = ({ blogBoxStyle }) => {
  const BlogQuery = useStaticQuery(graphql`query ListImageBlogQuery {
  allMarkdownRemark(
    filter: {frontmatter: {isPublished: {eq: true}}}
    sort: {fields: frontmatter___sort_order, order: DESC}
    limit: 2
  ) {
    totalCount
    edges {
      node {
        frontmatter {
          isPublished
          sort_order
          categories
          id
          title
          author {
            name
            image {
              childImageSharp {
                gatsbyImageData(width: 32, height: 32, layout: FIXED)
              }
            }
          }
          format
          quote_text
          quote_author
          video_link
          date(formatString: "LL")
          featured_image {
            childImageSharp {
              gatsbyImageData(width: 770, height: 420, layout: CONSTRAINED)
            }
          }
        }
        fields {
          slug
          authorId
          dateSlug
        }
        excerpt
      }
    }
  }
}
`);
  const blogs = BlogQuery.allMarkdownRemark.edges;
  const { totalCount } = BlogQuery.allMarkdownRemark;
  const postsPerPage = 2;
  const numberOfPages = Math.ceil(totalCount / postsPerPage);
  return (
    <Fragment>
      <BlogWrapper>
        {blogs.map((blog) => (
          <BlogBox key={blog.node.fields.slug}>
            <Blog content={blog.node} />
          </BlogBox>
        ))}
      </BlogWrapper>
      <Pagination
        rootPage="/blog"
        currentPage={1}
        numberOfPages={numberOfPages}
      />
    </Fragment>
  );
};

export default BlogArea;
